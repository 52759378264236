import { Show, createEffect, createSignal, onMount, splitProps } from "solid-js";
import { ImageProps } from "./model";
import { Atom } from ":mods";

export function Image(props: ImageProps) {
  const [local, others] = splitProps(props, ["elements", "events", "options", "class"]);
  const [uploadImg, setUploadImg] = createSignal("");
  const ImageInput = Atom.Form.createInputFile(undefined);
  const uploadedImages = [];
  const imagesList = local.elements?.images;
  const imageView = local.elements?.image;
  const clases = local?.class;
  const addImagePlaceholder = local.options?.addImagePlaceholder;
  const onImageChange = local.events?.onImageChange;

  createEffect(() => {
    const value = ImageInput.control.value;
    if (value) {
      // console.log("inside uploadImage");
      const file = value[0];
      uploadedImages.push(file);
      const imgLink = URL.createObjectURL(file);
      if (uploadedImages.length < 2) {
        // console.log("file uploaded");
        imagesList.push(file);
        setUploadImg(imgLink);
        onImageChange(true);
      } else {
        const idx = imagesList.findIndex((img) => img === uploadedImages[uploadedImages.length - 2]);
        // console.log("Idx:: if imagesUploaded>1", idx);
        if (idx >= 0) {
          imagesList[idx] = file;
          setUploadImg(imgLink);
          onImageChange(true);
        }
      }
    }
  });

  if (imageView.url) {
    setUploadImg(imageView.url);
  }

  return (
    <>
      <ImageInput.InputFile
        placeholder={uploadImg()}
        classes={{
          container: `flex justify-center items-center w-full h-full ${clases ?? ""}`,
          input: "w-full h-full !visible",
          // input_text: "mx-auto",
        }}
        elements={{
          input({ onInputClicked, drag }) {
            return (
              <Show when={local.options?.editable}>
                <div
                  class="flex flex-col !w-full h-full text-16px items-center justify-center cursor-pointer space-y-2"
                  onClick={onInputClicked}
                >
                  <div class="flex flex-row w-fit h-fit space-x-2  items-center justify-center bg-black text-white rounded-sm px-3 py-1 opacity-70">
                    <i class="icon-local:white-upload w-24px h-24px" />
                    <span>{addImagePlaceholder ?? "Add image"}</span>
                  </div>
                  {drag && (
                    <div class="flex flex-row w-fit h-fit space-x-2  items-center justify-center bg-black text-white rounded-sm px-3 py-1 opacity-70 text-10px">
                      <span>Drop Files Here</span>
                    </div>
                  )}
                </div>
              </Show>
            );
          },
          viewer: ({ placeholder }) => {
            // console.log("placeholder", placeholder);
            return (
              <div class={`w-full h-full !bg-cover ${clases ?? ""}`}>
                <img src={placeholder} class="w-100% h-100% object-cover" />
              </div>
            );
          },
        }}
      />
    </>
  );
}
